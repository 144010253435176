import { Pagination, Navigation, Controller } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./caroussel2.scss";
import image1 from "../../../images/cartao_1.jpg";
import image2 from "../../../images/cartao_2.jpg";
import image3 from "../../../images/cartao_3.jpg";

const Caroussel2 = () => {
  return (
    <section className="caroussel2">
      <Swiper
        slidesPerView={1}
        spaceBetween={14}
        centeredSlides={true}
        loop={true}
        pagination={{
          clickable: true,
          el: ".caroussel2 .swiper-pagination-custom",
        }}
        navigation={{ nextEl: ".caroussel2 .swiper-next", prevEl: ".caroussel2 .swiper-prev" }}
        breakpoints={{
          992: {
            slidesPerView: 2,
            spaceBetween: 14,
          },
        }}
        modules={[Pagination, Navigation, Controller]}>
        <SwiperSlide className="container">
          <div className="flex row stretch align-center content">
            <div className="text">
              <p>Receba pagamentos com emissão de boletos inteligentes</p>
              <p>
                <strong>Boletos</strong>
              </p>
              <p>Emita boletos para seus clientes e acompanhe o pagamento para evitar inadimplência.</p>
            </div>
            <img src={image1} alt="O cartão é gratuito" width="329" height="415" />
          </div>
        </SwiperSlide>
        <SwiperSlide className=" container">
          <div className="flex row stretch align-center content">
            <div className="text">
              <p>Faça transferências gratuitas </p>
              <p>
                <strong>Pix</strong>
              </p>
              <p>Aqui no Banco Capilé o PIX PJ é gratuito. Transfira a qualquer dia e horário, e receba instantaneamente.</p>
            </div>
            <img src={image2} alt="A conta é digital" width="329" height="415" />
          </div>
        </SwiperSlide>
        <SwiperSlide className="container">
          <div className="flex row stretch align-center content">
            <div className="text">
              <p>Facilite ainda mais os recebimentos da sua empresa por boleto</p>
              <p>
                <strong>Cobrança Ativa</strong>
              </p>
              <p>Não perca tempo cobrando seus clientes, reduza a inadimplência e ganhe tempo com notificações e relatórios de acompanhamento.</p>
            </div>
            <img src={image3} alt="Tem gestão de cobrança" width="329" height="415" />
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="flex row center align-stretch controls">
        <span className="swiper-prev">
          <svg width="18" height="28" viewBox="0 0 18 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.25 26.25C11.8022 26.25 11.3542 26.0791 11.0129 25.7373L0.512939 15.2373C-0.170654 14.5537 -0.170654 13.4463 0.512939 12.7627L11.0129 2.2627C11.6965 1.5791 12.804 1.5791 13.4875 2.2627C14.1711 2.94629 14.1711 4.05371 13.4875 4.7373L4.22458 14L13.4886 23.2641C14.1722 23.9477 14.1722 25.0551 13.4886 25.7387C13.1468 26.0805 12.6984 26.25 12.25 26.25Z" fill="black" />
          </svg>
        </span>
        <div className="swiper-pagination-custom"></div>
        <span className="swiper-next">
          <svg width="18" height="28" viewBox="0 0 18 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_18_26)">
              <path d="M5.24997 26.2502C4.80219 26.2502 4.35419 26.0793 4.01294 25.7375C3.32935 25.0539 3.32935 23.9465 4.01294 23.2629L13.2781 14.0002L4.01294 4.73779C3.32935 4.0542 3.32935 2.94678 4.01294 2.26318C4.69653 1.57959 5.80396 1.57959 6.48755 2.26318L16.9875 12.7632C17.6711 13.4468 17.6711 14.5542 16.9875 15.2378L6.48755 25.7378C6.14685 26.0807 5.69841 26.2502 5.24997 26.2502Z" fill="black" />
            </g>
            <defs>
              <clipPath id="clip0_18_26">
                <rect width="17.5" height="28" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>
      </div>
    </section>
  );
};

export default Caroussel2;
