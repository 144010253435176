import "./button.scss";

const Button = ({ name, color, textcolor, children, ...props }) => {
  return (
    <a className="button" style={{ backgroundColor: color, color: textcolor }} {...props} aria-label={name}>
      {children ? children : name}
    </a>
  );
};

export default Button;
