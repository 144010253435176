import Button from "../../fragments/Button/Button";
import Logo from "../../images/logo_banco_capile.png";
import LogoMobile from "../../images/logo_banco_capile_mobile.png";
import "./header.scss";

const Header = () => {
  return (
    <header>
      <div className="flex row space-between align-center container">
        <div className="logo">
          <picture>
            <source media="(max-width: 780px)" srcSet={LogoMobile} width="191" height="20" />
            <source media="(min-width: 781px)" srcSet={Logo} width="178" height="25" />
            <img src={Logo} alt="Banco Capilé" width="178" height="25" />
          </picture>
        </div>
        <Button name="Abra sua conta" href="#" color="var(--color-primary)" textcolor="var(--color-text-primary)" />
      </div>
    </header>
  );
};

export default Header;
