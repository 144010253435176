import "./security.scss";

const Security = () => {
  return (
    <section className="security">
      <div className="flex column container">
        <div className="flex row space-between align-stretch top">
          <div className="left">
            <p>
              O Banco Capilé É SEGURO<span>.</span>
            </p>
          </div>
          <div className="right">
            <h2>Certificado de segurança</h2>
            <p>Somos certificados pela Autoridade Certificadora líder na América Latina, e especialista em Identificação Digital, Certisign.</p>
            <h2>
              Monitoramento
              <br /> anti-fraude
            </h2>
            <p>Analisamos e monitoramos logins para a segurança da conta da sua empresa.</p>
            <h2>Transações seguras</h2>
            <p>Validamos todas as transações via token de acordo com as exigências do Banco Central.</p>
            <h2>
              Níveis de segurança
              <br /> da Visa
            </h2>
            <p>Proteção ao uso não autorizado dos dados dos cartões da sua empresa.</p>
          </div>
        </div>
        <div className="flex row center align-center bottom">
          <p>
            EMPREENDER É ACREDIT<span>@</span>R
          </p>
        </div>
      </div>
    </section>
  );
};

export default Security;
