import { useState } from "react";
import { Pagination, Navigation, Controller } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./caroussel.scss";
import image1 from "../../../images/cartao_1.jpg";
import image2 from "../../../images/cartao_2.jpg";
import image3 from "../../../images/cartao_3.jpg";
import Button from "../../../fragments/Button/Button";

const Caroussel = () => {
  const [swiper1, setSwiper1] = useState(null);
  const [swiper2, setSwiper2] = useState(null);
  let isSwiping1 = false;
  let isSwiping2 = false;

  return (
    <section className="container flex column center align-center caroussel">
      <div className="container">
        <div className="caroussel__images">
          <div className="flex row center content">
            <Swiper
              onSwiper={setSwiper1}
              onSlideChange={(e) => {
                const { realIndex } = e;
                if (swiper2 !== null && isSwiping2 === false) {
                  isSwiping1 = true;
                  swiper2.slideToLoop(realIndex);
                  setTimeout(() => {
                    isSwiping1 = false;
                  }, 300);
                }
              }}
              slidesPerView={1}
              spaceBetween={0}
              centeredSlides={true}
              loop={true}
              pagination={{
                clickable: true,
              }}
              navigation={false}
              modules={[Pagination, Navigation, Controller]}
              breakpoints={{
                992: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              className="mySwiper">
              <SwiperSlide>
                <img src={image1} alt="O cartão é gratuito" width="422" height="560" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={image2} alt="A conta é digital" width="422" height="560" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={image3} alt="Tem gestão de cobrança" width="422" height="560" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div className="caroussel__texts">
          <div className="flex row center content">
            <Swiper
              onSwiper={setSwiper2}
              onSlideChange={(e) => {
                const { realIndex } = e;
                if (swiper1 !== null && isSwiping1 === false) {
                  isSwiping2 = true;
                  swiper1.slideToLoop(realIndex);
                  setTimeout(() => {
                    isSwiping2 = false;
                  }, 300);
                }
              }}
              slidesPerView={1}
              spaceBetween={0}
              centeredSlides={true}
              loop={true}
              navigation={false}
              modules={[Pagination, Navigation, Controller]}
              breakpoints={{
                992: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              className="mySwiper2">
              <SwiperSlide>
                <div className="flex column center">
                  <h2>Cartão virtual</h2>
                  <p>Aproveite as facilidades de fazer compras online e assinar serviços recorrentes.</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex column center">
                  <h2>Cartão físico</h2>
                  <p>Use o cartão físico corporativo para compras da sua empresa em estabelecimentos.</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex column center">
                  <h2>Cartões para sua equipe</h2>
                  <p>Distribua cartões para a sua equipe e tenha controle de diferentes centros de custos.</p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div className="flex row center">
          <Button href="#" textcolor="var(--color-text-primary)" color="var(--color-primary)">
            Abra sua conta
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Caroussel;
