import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./benefits.scss";
import benefit1 from "../../../images/cartao_1.jpg";
import benefit2 from "../../../images/cartao_2.jpg";
import benefit3 from "../../../images/cartao_3.jpg";

const Benefits = () => {
  const [swiper, setSwiper] = useState(null);
  const [barPosition, setBarPosition] = useState({ top: 0, height: 160 });

  function moveBar(evt) {
    const { target } = evt;
    const { offsetParent } = target;
    const parentComputedStyle = window.getComputedStyle(offsetParent);
    const childElementCount = offsetParent.childElementCount - 1;
    const height = parseInt(parentComputedStyle["height"].replace("px", "")) / childElementCount;
    const position = parseInt(target.dataset.position);
    const top = position * height;
    setBarPosition({ top, height });
    swiper.slideTo(position);
  }

  return (
    <section className="container flex row space-between benefits">
      <div className="text">
        <ul className="flex column center align-center text">
          <li onMouseEnter={moveBar} data-position="0">
            <img className="mobile" src={benefit1} alt="O cartão é gratuito" width="422" height="560" />
            <h2>O cartão é gratuito</h2>
            <p>Aproveite os cartões gratuitos. O físico para compras em estabelecimentos e o virtual para compras online e recorrentes. Emita cartões adicionais para sua equipe.</p>
          </li>
          <li onMouseEnter={moveBar} data-position="1">
            <img className="mobile" src={benefit2} alt="A conta é digital" width="422" height="560" />
            <h2>A conta é digital</h2>
            <p>Seja no app ou no navegador, tenha um extrato inteligente, receba e faça transferências, use o PIX gratuito, pague boletos e impostos e tenha multiusuários com diferentes permissões para acessar a conta.</p>
          </li>
          <li onMouseEnter={moveBar} data-position="2">
            <img className="mobile" src={benefit3} alt="Tem gestão de cobrança" width="422" height="560" />
            <h2>Tem gestão de cobrança</h2>
            <p>Emita e gerencie boletos com a Cobrança Ativa e também faça suas cobranças por PIX.</p>
          </li>
          <li className="bar" style={{ ...barPosition }}></li>
        </ul>
      </div>
      <div className="image desktop">
        <div className="flex row center content">
          <Swiper spaceBetween={5} slidesPerView={1} onSwiper={setSwiper}>
            <SwiperSlide>
              <img src={benefit1} alt="O cartão é gratuito" width="422" height="560" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={benefit2} alt="A conta é digital" width="422" height="560" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={benefit3} alt="Tem gestão de cobrança" width="422" height="560" />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Benefits;
