import "./Facilities.scss";
import facilitiesBanner from "../../../images/facilities.jpg";
import facilitiesBannerMobile from "../../../images/facilities-mobile.jpg";

const Facilities = () => {
  return (
    <section className="facilities">
      <div className="container flex row space-between">
        <div className="text">
          <div className="item">
            <h2>Conta sem mensalidade</h2>
            <p>Sem mensalidade, taxa de abertura e de manutenção. Pague apenas pelo que usar.</p>
          </div>
          <div className="item">
            <h2>Abertura rápida de conta</h2>
            <p>Faça seu cadastro em minutos e tenha sua conta aberta em até 2 dias úteis.</p>
          </div>
          <div className="item">
            <h2>Atendimento especializado</h2>
            <p>Conte com um atendimento online pronto para te trazer soluções.</p>
          </div>
          <div className="item">
            <h2>Organização financeira</h2>
            <p>Extrato automatizado, cartões adicionais e usuários com diferentes acessos.</p>
          </div>
        </div>
        <div className="image">
          <picture>
            <source media="(max-width: 990px)" srcSet={facilitiesBannerMobile} width="700" height="676" />
            <source media="(min-width: 991px)" srcSet={facilitiesBanner} width="574" height="744" />
            <img src={facilitiesBanner} alt="Banco Capilé, facilidades na palma da sua mão" width="574" height="744" />
          </picture>
        </div>
      </div>
    </section>
  );
};

export default Facilities;
