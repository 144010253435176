import "./bigText.scss";
import { useEffect, useRef } from "react";

const BigText = ({ phrases }) => {
  const container = useRef();

  useEffect(() => {
    const handleScroll = () => {
      if (container.current) {
        const windowScroll = window.scrollY;
        Array.from(container.current.children).forEach((child, index) => {
          if (windowScroll + child.offsetHeight + 60 > child.offsetTop) child.classList.add("full-opacity");
          else if (index > 0) child.classList.remove("full-opacity");
          if (windowScroll + child.offsetHeight - child.offsetHeight + 50 > child.offsetTop) child.classList.add("zero-opacity");
          else child.classList.remove("zero-opacity");
        });
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <section ref={container} className="big-text flex column container">
      {phrases.map((phrase, index) => {
        return (
          <div key={`big-text__phrase--${index}`} className={`big-text__phrase${index === 0 ? " full-opacity" : ""}`}>
            <span>{phrase.slice(0, 1)}</span>
            {phrase.slice(1, phrase.length)}
          </div>
        );
      })}
    </section>
  );
};

export default BigText;
