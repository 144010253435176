import "./openYourAccount.scss";
import Button from "../../../fragments/Button/Button";

const OpenYourAccount = () => {
  return (
    <section className="open-your-account">
      <div className="container">
        <h2>Abra sua conta em minutos</h2>
        <ul>
          <li>
            <h3>Cadastro</h3>
            <p>Abra sua conta PJ em minutos, de forma 100% digital e sem papelada.</p>
          </li>
          <li>
            <h3>Ativação</h3>
            <p>Acesse o aplicativo e realize a ativação da sua conta digital PJ.</p>
          </li>
          <li>
            <h3>Sucesso!</h3>
            <p>Adicione saldo e aproveite todas as funcionalidades da sua conta Capilé.</p>
          </li>
        </ul>
        <div className="flex row center bottom">
          <Button href="#" textcolor="var(--color-text-primary)" color="var(--color-primary)">
            Abra sua conta
          </Button>
        </div>
      </div>
    </section>
  );
};

export default OpenYourAccount;
