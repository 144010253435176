import "./home.scss";
import Banner from "./sections/Banner";
import Benefits from "./sections/Benefits";
import BigText from "./sections/BigText";
import Qualities from "./sections/Qualities";
import Facilities from "./sections/Facilities";
import Caroussel from "./sections/Caroussel";
import Caroussel2 from "./sections/Caroussel2";
import Security from "./sections/Security";
import OpenYourAccount from "./sections/OpenYourAccount";

const Home = () => {
  return (
    <main className="home">
      <Banner />
      <Benefits />
      <BigText phrases={["-BUROCRACIA", "+TEMPO", "-BANCO", "+NEGÓCIO"]} />
      <Qualities />
      <Facilities />
      <Caroussel />
      <Caroussel2 />
      <Security />
      <OpenYourAccount />
    </main>
  );
};

export default Home;
