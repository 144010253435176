import Button from "../../../fragments/Button/Button";
import "./Qualities.scss";

const Qualities = () => {
  return (
    <section className="container flex column left qualities">
      <p>Somos um banco digital para você ter tempo para o que importa: seu negócio.</p>
      <Button href="#" textcolor="var(--color-text-primary)" color="var(--color-primary)">
        Abra sua conta
      </Button>
    </section>
  );
};

export default Qualities;
