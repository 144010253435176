import BannerDesktop from "../../../images/banner-principal.jpg";
import BannerMobile from "../../../images/banner-principal-mobile.jpg";

const Banner = () => {
  return (
    <section className="banner-main">
      {window.innerWidth < 780 && <link rel="preload" as="image" href={BannerMobile} />}
      <picture>
        <source media="(max-width: 780px)" srcSet={BannerMobile} width="780" height="426" />
        <source media="(min-width: 781px)" srcSet={BannerDesktop} width="1920" height="680" />
        <img src={BannerDesktop} alt="O BANCO DE QUEM EMPREENDE" width="1920" height="680" />
      </picture>
      <div className="container">
        <h1 className="text">
          O B<span>@</span>NCO <br /> DE QUEM <br /> EMPREENDE
        </h1>
      </div>
    </section>
  );
};

export default Banner;
